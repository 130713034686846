import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import BaseDeCobrosDetalle from 'src/app/shared/models/BaseDeCobrosDetalle';
import { basedecobros } from 'src/app/shared/services/basdecobros.service'; 
import  {IResultadoGeneral}  from 'src/app/shared/models/ResultadoGeneral';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import User from 'src/app/shared/models/user';
import { HttpService } from 'src/app/shared/services/http.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import IExportarBaseDetalle from 'src/app/shared/models/ExportarBaseDetalle';
import { Estatus } from 'src/app/shared/models/Estatus';



@Component({
  selector: 'app-base-de-cobros-detalle',
  templateUrl: './base-de-cobros-detalle.component.html',
  styleUrls: ['./base-de-cobros-detalle.component.scss']
})
export class BaseDeCobrosDetalleComponent implements OnInit {
  @ViewChild('filtroInput') filtroInput!: ElementRef;
  @ViewChild('dt1') dt!: Table;
  basedecobrosdetalle: BaseDeCobrosDetalle[] = [];
  tablaExportar: IExportarBaseDetalle[] = [];
  usuario:User={};
  fecha_dia:any;
  numero_operacion:number=0;
  color_gris_deshabilitadas:string = '#D9D9D9';
  dspbasedecobros:boolean=false;
  texto_operacion: string = '';
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  

    objEstatus:Estatus[]=[{
      clv_activo:0,
      des_estatus:'Desactivado'
    },
    {
      clv_activo:1,
      des_estatus:'Activo'
    }]

  baseDeCobrosDetalleForm: FormGroup = new FormGroup({
    id_config_unidad: new FormControl('', [Validators.required]),
    des_config_unidad: new FormControl('', [Validators.required]),
    id_concepto: new FormControl(0, []),
    des_concepto: new FormControl('', []),
    importe: new FormControl(0,[Validators.required , Validators.min(0)]),
    id_categoria: new FormControl(0, []),
    des_categoria: new FormControl(0, []),
    clv_activo: new FormControl(0, []),
    idx: new FormControl(0, [])
});
  data_editar_basedecobros: BaseDeCobrosDetalle={
    opcion:0,
    cod_usuario:'',
    idx:0,
    id_concepto:0,
    des_concepto:'',
    importe:0,
    id_config_unidad:0,
    des_config_unidad:'',
    id_categoria:0,
    des_categoria:'',
    clv_activo:0,
  };

  constructor(private http:HttpService, private message:MessageService, private auth:AuthService,
    private confirmationService: ConfirmationService,private basedecobrosService:basedecobros){
    this.cargarInformacionUsuario();
  }

  cargarInformacionUsuario(){
    this.usuario = this.auth.user;
  }

  ngOnInit(): void {
    this.inicializarFechas();
    this.GetBaseDeCobrosDetalle();
  }

  inicializarFechas(){
    var getYear = new Date().toLocaleDateString('es-MX',{ year: 'numeric'});   
    var getMonth = new Date().toLocaleDateString('es-MX',{ month: '2-digit'});
    var getDay = new Date().toLocaleDateString('es-MX',{day: '2-digit'});
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia=dateFormat;
  }

  GetBaseDeCobrosDetalle(): void {
    this.basedecobrosService.getbasedecobrosdetalle().subscribe({
      next: (data) => {
        this.basedecobrosdetalle = data; 
      },
      error: (err) => {
        console.error('Error al cargar los datos de base de cobros', err); 
      }
    });
  }

  mostrarModalEditar(data: BaseDeCobrosDetalle, num_operacion: number) {
    this.dspbasedecobros = true; // Muestra el diálogo.
    this.data_editar_basedecobros = data; // Asigna los datos de la fila seleccionada.
    this.numero_operacion = num_operacion;
    
    // Llenar el formulario con los datos a editar.
    this.baseDeCobrosDetalleForm.patchValue({
        idx: data.idx,
        id_concepto: data.id_concepto,
        des_concepto: data.des_concepto,
        importe: data.importe,
        id_config_unidad: data.id_config_unidad,
        des_config_unidad: data.des_config_unidad,
        id_categoria: data.id_categoria,
        des_categoria: data.des_categoria,
        clv_activo: data.clv_activo
    });

    // Cambiar el texto de la operación.
    this.texto_operacion = ' Editar Importe Base de Cobros Detalle Configuración ' + data.des_config_unidad + ', Concepto: ' +  data.des_concepto;
}


  confirmarDesactivar(data: BaseDeCobrosDetalle) {
    let estatus_original= data.clv_activo;
    let texto_desactivar: string = data.clv_activo == 1 ? 'DESACTIVAR' : 'ACTIVAR';
    let texto_desactivar_header: string = data.clv_activo == 1 ? 'Desactivar' : 'Activar';
    this.confirmationService.confirm({
      message: 'Esta seguro que desea <b>' + texto_desactivar + '</b> el concepto <b>'+data.des_concepto+' </b>' + '</b> de la configuración <b>'+data.des_config_unidad+' </b>?' + '</b> , esto afectará la base de cobros principal. <b>',
      header: 'Confirmacion de ' + texto_desactivar_header,
      icon: 'pi pi-info-circle',
      accept: () => {
        data.cod_usuario=this.usuario.cod;
        data.opcion=2;
        data.clv_activo = data.clv_activo == 1 ? 0 : 1;
        this.guardarInformacionDesactivar(data);
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'Se ha Rechazado' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_activo=estatus_original;
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Se ha Cancelado' });
            break;
        }
      }
    });
  }
 
  guardarInformacionDesactivar(data: BaseDeCobrosDetalle) {
    this.basedecobrosService.editbasedecobrosdetalle(data).subscribe((resp)=> {
      var resultado=resp;
      if(resultado.clv_estatus==1){
        setTimeout(() => {
          this.message.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.baseDeCobrosDetalleForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      }
    },
    (error)=> {
      this.message.add({ severity: 'error', summary: 'Error en <Editar Base de Cobros>', detail: 'Contacte al Administrador del Sitio' });
    });
  }

  guardarInformacion() {
  // Validación del formulario
  if (this.baseDeCobrosDetalleForm.invalid) {
    this.message.add({ key: 'msj', severity: 'error', summary: 'Formulario Inválido', detail: 'Por favor complete todos los campos correctamente.' });
    return;
  }
  
    // Extraer los valores antes de la confirmación
    const data_enviar: BaseDeCobrosDetalle = {
      idx: this.baseDeCobrosDetalleForm.get('idx')?.value,
      des_config_unidad: this.baseDeCobrosDetalleForm.get('des_config_unidad')?.value,
      clv_activo: typeof this.baseDeCobrosDetalleForm.get('clv_activo')?.value === 'object' ? 1 : this.baseDeCobrosDetalleForm.get('clv_activo')?.value,
      cod_usuario: this.usuario.cod,
      opcion: this.numero_operacion,
      id_config_unidad: this.baseDeCobrosDetalleForm.get('id_config_unidad')?.value, 
      id_concepto: this.baseDeCobrosDetalleForm.get('id_concepto')?.value,
      des_concepto: this.baseDeCobrosDetalleForm.get('des_concepto')?.value,  
      importe: this.baseDeCobrosDetalleForm.get('importe')?.value, 
      id_categoria: this.baseDeCobrosDetalleForm.get('id_categoria')?.value,
      des_categoria: this.baseDeCobrosDetalleForm.get('des_categoria')?.value,  
    };
  
    // Mostrar la confirmación antes de guardar
    this.confirmationService.confirm({
      message: `¿Está seguro que desea guardar el concepto <b>${data_enviar.des_concepto}</b> de la configuración <b>${data_enviar.des_config_unidad}</b>?`,
      header: 'Confirmación de Guardado',
      icon: 'pi pi-info-circle',
      accept: () => {
        // Si el usuario acepta, proceder con el guardado
        this.inicializarFechas(); // Llamar a inicializarFechas si es necesario antes de enviar
        
        this.basedecobrosService.editbasedecobrosdetalle(data_enviar).subscribe((resp) => {
          const resultado = resp;
          if (resultado.clv_estatus === 1) {
            setTimeout(() => {
              this.GetBaseDeCobrosDetalle(); // Volver a cargar la lista de base de cobros
              this.message.add({ severity: 'success', summary: 'Operación Exitosa', detail: 'La información se almacenó correctamente' });
              this.baseDeCobrosDetalleForm.reset();
              this.cancelarVentanaModal();
            }, 800);
          }
        }, (error) => {
          this.message.add({ severity: 'error', summary: 'Error en <Guardar Base de Cobros>', detail: 'Contacte al Administrador del Sitio' });
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'error', summary: 'Rechazado', detail: 'El guardado fue rechazado.' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'El guardado fue cancelado.' });
            break;
        }
      }
    });
  }
  
GenerarTodoAExcel() {
  if (this.basedecobrosdetalle.length === 0) {
    return this.message.add({
      key: 'msj',
      severity: 'warn',
      summary: 'No Existen Tabulados',
      detail: 'No Existen Tabulados para Exportar'
    });
  }

  // Mapeamos la data a la estructura de IExportarBase
  this.tablaExportar = this.basedecobrosdetalle.map((data) => {
    return {
      id_config_unidad: data.id_config_unidad,
      tipo_configuracion: data.des_config_unidad,
      id_categoria: data.id_categoria,
      des_categoria: data.des_categoria,
      id_concepto: data.id_concepto,
      des_concepto: data.des_concepto,
      importe: data.importe,
      clv_activo: data.clv_activo,
    };
  });

  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tablaExportar);

  // Verificar si worksheet['!ref'] existe, si no, establecerlo
  if (!worksheet['!ref']) {
    worksheet['!ref'] = XLSX.utils.encode_range({
      s: { r: 0, c: 0 },
      e: { r: this.tablaExportar.length - 1, c: Object.keys(this.tablaExportar[0]).length - 1 }
    });
  }
  // Ajustar el rango de celdas
  const range = XLSX.utils.decode_range(worksheet['!ref']!);
  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

      // Inicializar celda si no existe
      if (!worksheet[cellRef]) {
        worksheet[cellRef] = { v: '', s: {} }; // Inicializar celdas vacías
      }
      
      // Aplicar estilos y formato
      if (row === 0) {
        // Encabezados
        worksheet[cellRef].s = {
          font: { bold: true, name: 'Arial', sz: 12 },
          alignment: { horizontal: 'center' },
        };
      } else {
        // Estilos para filas de datos
        const alignment = this.getAlignment(col);
        worksheet[cellRef].s = {
          font: { name: 'Arial', sz: 12 },
          alignment: { horizontal: alignment }
        };

        // Formato de moneda para columnas específicas
        if ([6].includes(col)) {
          worksheet[cellRef].z = '"$"#,##0.00'; // Formato de moneda en pesos mexicanos
        }
      }
    }
  }

  // Ajustar el ancho de las columnas
  worksheet['!cols'] = [
    { wpx: 20 }, // id_config_unidad
    { wpx: 80 }, // tipo_configuracion
    { wpx: 80 }, // id_categoria
    { wpx: 80 }, // des_categoria
    { wpx: 80 }, // id_concepto
    { wpx: 80 }, // des_concepto
    { wpx: 80 }, // importe
    { wpx: 80 }, // clv_activo
  ];

  const workbook: XLSX.WorkBook = {
    Sheets: { 'base de cobros': worksheet },
    SheetNames: ['base de cobros']
  };

  // Guardar el archivo
  const excelBuffer: any = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  });
  const fechaRegistro = new Date().toLocaleDateString('es-MX', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\//g, '-');

  this.saveExcelFile(excelBuffer, 'base_de_cobros_detalle_' + fechaRegistro);
}

private getAlignment(col: number): string {
  if (col === 0) return 'left';  // id_config_unidad a la izquierda
  if (col === 1) return 'center'; // tipo_configuracion al centro
  if (col === 2 || col === 3) return 'center'; // km_inicial y km_final al centro
  return 'right'; // resto de columnas a la derecha
}

private saveExcelFile(buffer: any, fileName: string): void {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
  saveAs(data, `${fileName}.xlsx`);
}



  cancelarVentanaModal() {
    this.texto_operacion = '';
    //this.num_operacion=0;
    this.dspbasedecobros = false;
    this.baseDeCobrosDetalleForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }
    applyFilterGlobal($event: any, stringVal: any) {
      this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
    }
    limpiarFiltro() {
      this.filtroInput.nativeElement.value = ''; // Establecer el valor del filtro en una cadena vacía
      // Puedes llamar a la función applyFilterGlobal aquí si es necesario
    }
    clear(table: Table) {
      table.clear();
      this.limpiarFiltro();
  }

  getUniqueCategories(data: any[]): any[] {
    if (!data) return [];
    return data.filter((item, index, self) =>
      index === self.findIndex((t) => t.des_categoria === item.des_categoria)
    );
  }
  getUniqueConfig(data: any[]): any[] {
    if (!data) return [];
    return data.filter((item, index, self) =>
      index === self.findIndex((t) => t.des_config_unidad === item.des_config_unidad)
    );
  }

  calcularSuma(dt: Table): number {
    const datosVisibles = dt.filteredValue || this.basedecobrosdetalle; // Usa los datos filtrados o el total
    return datosVisibles
        .filter(item => item.clv_activo === 1) // Solo elementos activos
        .reduce((suma, item) => suma + (item.importe || 0), 0); // Suma los importes
}
}

