<p-toast position="top-right" key="msj"></p-toast>
<p-confirmDialog [style]="{width: '25vw'}" [baseZIndex]="10000" acceptLabel="SI" rejectLabel="NO"
    rejectButtonStyleClass="p-button-text"></p-confirmDialog>
<p-confirmDialog [style]="{width: '25vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
    rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar"
    [rejectVisible]="false"></p-confirmDialog>
<div class="d-flex justify-content-center">
    <div class="container-fluid" style="width: 90%;">
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="card card-shadow sinborde">
                    <div class="card-header sinborde" style="background: none;">
                        <div class="cardicono rounded">
                            <i class="pi pi-calculator mt-3" style="font-size: 2rem; color: #fff;"></i>
                        </div>
                        <header class="posicion" style="left: 6%; top: 0%;">Base De Cobros Detalle</header>
                    </div>
                    <div class="card-content">
                        <div class="row mt-3">
                            <p-table #dt1 [value]="basedecobrosdetalle" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm p-datatable-striped"
                                dataKey="id_config_unidad" [rows]="500" 
                                [showCurrentPageReport]="true" [rowsPerPageOptions]="[500, 1000, 1500]" [resizableColumns]="false"
                                [paginator]="true" responsiveLayout="scroll" 
                                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} registros"
                                [globalFilterFields]="['id_config_unidad', 'des_concepto']">
                                <ng-template pTemplate="caption">
                                    <div class="flex">
                                        <div class="col-md-2">
                                            <button pButton label="Limpiar" class="p-button-outlined"
                                            icon="pi pi-filter-slash" (click)="limpiarFiltro()" (click)="clear(dt1)" >
                                            </button>
                                        </div>
                                        <div class="col-md-2">
                                            <p-columnFilter field="des_categoria" matchMode="contains" [showMenu]="false">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                    <p-dropdown [ngModel]="value" [options]="getUniqueCategories(basedecobrosdetalle)"
                                                        optionLabel="des_categoria" optionValue="des_categoria"
                                                        (onChange)="filter($event.value)"
                                                        placeholder="Filtrar por categoría" [showClear]="true">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-columnFilter>
                                        </div>
                                        <div class="col-md-2">
                                            <p-columnFilter field="des_config_unidad" matchMode="contains" [showMenu]="false">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                    <p-dropdown [ngModel]="value" [options]="getUniqueConfig(basedecobrosdetalle)"
                                                        optionLabel="des_config_unidad" optionValue="des_config_unidad"
                                                        (onChange)="filter($event.value)"
                                                        placeholder="Filtrar por configuración" [showClear]="true">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-columnFilter>
                                        </div>
                                        <div class="col-md-2">
                                            <p-columnFilter field="clv_activo" matchMode="contains" [showMenu]="false">
                                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                    <p-dropdown [ngModel]="value" [options]="objEstatus"
                                                        optionLabel="des_estatus" optionValue="clv_activo"
                                                        (onChange)="filter($event.value)"
                                                        placeholder="Filtrar por estatus" [showClear]="true">
                                                    </p-dropdown>
                                                </ng-template>
                                            </p-columnFilter>
                                        </div>
                                        <div class="col-md-2">
                                            <span class="ml-auto">
                                                <i class="pi pi-search mover"></i>
                                                <input pInputText #filtroInput id="filtro" type="text"
                                                    (input)="applyFilterGlobal($event, 'contains')"
                                                    placeholder="Buscar Descripción" />
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <button pButton label="Exportar Todo a Excel"
                                            class="p-button-outlined qolor ml-auto p-button-raised" icon="pi pi-file-excel"
                                            pTooltip="Haz clic para exportar todos los datos a Excel"
                                            style="color: #ffffff; background-color: rgb(62, 139, 86)"
                                            (click)="GenerarTodoAExcel()">
                                        </button>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr [ngClass]="{'texto_sm':true}">
                                        <th>#</th>
                                        <th style="min-width: 15rem">Tipo Configuración</th>
                                        <th style="min-width: 10rem">Categoria</th>
                                        <th style="min-width: 10rem">Descripcion</th>
                                        <th style="min-width: 10rem">Importe</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-data let-index="rowIndex">
                                    <tr [ngClass]="{'row-accessories': data.clv_activo === 0}">
                                        <td>
                                            <div class="texto_negrita">{{(index + 1)}}</div>
                                        </td>
                                        <td class="texto_negrita texto_naranja_ba">
                                            <div class="texto_negrita"> 
                                                <i class="fa-solid fa-van-shuttle"></i>{{data.des_config_unidad}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="texto_negrita"> 
                                                {{data.des_categoria}}
                                            </div>
                                        </td> 
                                        <td>
                                            <div class="texto_negrita"> 
                                                {{data.des_concepto}}
                                            </div>
                                        </td>        
                                        <td>
                                            <div class="texto_negrita">{{data.importe| currency}}</div>
                                        </td>
                                        <td>
                                            <p-tag *ngIf="data.clv_activo == 1" icon="pi pi-user" [rounded]="true" severity="success" value="Activo"></p-tag>
                                            <p-tag *ngIf="data.clv_activo == 0" icon="pi pi-user" [rounded]="true" severity="warning" value="Desactivado"></p-tag>
                                        </td>
                                        <td style="width: 130px;">
                                            <div class="d-flex justify-content-around">
                                                <button class="btn btn-warning" pTooltip="Editar Informacion"
                                                     tooltipPosition="left" (click)="mostrarModalEditar(data,1);">
                                                    <i class="pi pi-pencil"></i>
                                                </button>
                                                <button class="btn btn-info" pTooltip="Cambiar Estatus"
                                                         tooltipPosition="top" (click)="confirmarDesactivar(data);">
                                                    <i class="pi pi-delete-left"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="13">No se encontró ningún registro.</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td colspan="4" style="text-align: right"><strong>Total Importes:</strong></td>
                                        <td colspan="3">{{ calcularSuma(dt1) | currency }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="dspbasedecobros" [resizable]="false" [draggable]="false" [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3>{{ texto_operacion }}</h3> 
        </ng-template>
        <ng-template pTemplate="content">
            <form [formGroup]="baseDeCobrosDetalleForm" (ngSubmit)="guardarInformacion()">
                <div class="row">
                    <div class="col-md-4 form-group">
                        <label for="importe">IMPORTE</label>
                        <input type="number" id="importe" formControlName="importe" class="form-control" min="0">
                    </div>     
                </div>
            </form>
        </ng-template>
        <ng-template pTemplate="footer">
            <div style="display: flex; justify-content: flex-end; gap: 10px;">
                <button pButton label="Guardar" icon="pi pi-save" (click)="guardarInformacion()"></button>
                <button pButton label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="cancelarVentanaModal()"></button>
            </div>
        </ng-template>
    </p-dialog>
</div>