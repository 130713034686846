import { ProspeccionEstatusClienteComponent } from './pages/prospeccion-estatus-cliente/prospeccion-estatus-cliente.component';
import { CalendarioComponent } from './pages/calendario/calendario.component';
import { TipoProyectoComponent } from './pages/tipo-proyecto/tipo-proyecto.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { MainComponent } from './pages/main/main.component';
import { ClientesProspectoComponent } from './pages/clientes-prospecto/clientes-prospecto.component';

import { LoginGuard } from './shared/utils/guards/login.guard';
import { AuthGuard } from './shared/utils/guards/auth.guard';
import { MediosDeContactoComponent } from './pages/medios-de-contacto/medios-de-contacto.component';
import { ClasificacionDeClientesComponent } from './pages/clasificacion-de-clientes/clasificacion-de-clientes.component';
import { SubClasificacionDeClientesComponent } from './pages/sub-clasificacion-de-clientes/sub-clasificacion-de-clientes.component';
import { BaseDeCobrosComponent } from './pages/base-de-cobros/base-de-cobros.component';
import { BaseDeCobrosDetalleComponent } from './pages/base-de-cobros-detalle/base-de-cobros-detalle.component';
import { TipoDeRutaComponent } from './pages/tipo-de-ruta/tipo-de-ruta.component';
import { ImporteDeDieselComponent } from './pages/importe-de-diesel/importe-de-diesel.component';
import { ImporteDeGastosDeRutaAnualComponent } from './pages/importe-de-gastos-de-ruta-anual/importe-de-gastos-de-ruta-anual.component';
import { ImporteDeDiasParaUtilidadComponent } from './pages/importe-de-dias-para-utilidad/importe-de-dias-para-utilidad.component';
import { ComisionCombustibleSodexoComponent } from './pages/comision-combustible-sodexo/comision-combustible-sodexo.component';
import { TipoCombustibleComponent } from './pages/tipo-combustible/tipo-combustible.component';
import { TabuladorCostosComponent } from './pages/tabulador-costos/tabulador-costos.component';
import { PdfBriefComponent } from './pages/pdf-brief/pdf-brief.component';

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "clientes-prospecto",
        component: ClientesProspectoComponent
      },
      {
        path: "medios-de-contacto",
        component: MediosDeContactoComponent
      },
      {
        path: "clasificacion-de-clientes",
        component:ClasificacionDeClientesComponent
      },
      {
        path:"sub-clasificacion-de-clientes",
        component:SubClasificacionDeClientesComponent
      },
      {
        path:"base-de-cobros",
        component:BaseDeCobrosComponent
      },
      {
        path:"base-de-cobros-detalle",
        component:BaseDeCobrosDetalleComponent
      },
      {
        path:"tipo-de-ruta",
        component:TipoDeRutaComponent
      },
      {
        path:"importe-de-diesel",
        component:ImporteDeDieselComponent
      },
      {
        path:"importe-de-gastos-de-ruta-anual",
        component:ImporteDeGastosDeRutaAnualComponent
      },
      {
        path:"importe-de-dias-para-utilidad",
        component:ImporteDeDiasParaUtilidadComponent
      },
      {
        path:"comision-combustible-sodexo",
        component:ComisionCombustibleSodexoComponent
      },
      {
        path:"tipo-Proyecto",
        component:TipoProyectoComponent
      },
      {
        path:'tipo-combustible',
        component:TipoCombustibleComponent
      },
      {
        path:'calendario',
        component:CalendarioComponent
      },
      {
        path:'prospeccion-estatus-cliente',
        component:ProspeccionEstatusClienteComponent
      },
      {
        path:'tabulador-costos',
        component:TabuladorCostosComponent
      },
      {
        path:'pdf-brief',
        component:PdfBriefComponent
      }
    ]
  }
  ,
  {
    canActivate: [LoginGuard],
    path: "login",
    component: LoginComponent
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true}
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
